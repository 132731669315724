import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { BiChevronRight } from "react-icons/bi";
import Layout from "../../../containers/global/layout";
import Seo from "../../../components/seo";
import BannerText from "../../../components/BannerText";
import Banner from "../../../containers/Banner";
import { CareerFeature, SideBottomImage, TeamBackImg } from "../../../images/index";
import Button from "../../../components/Button";
import MotoList from "../../../containers/MotoList";
import CheckItOut from "../../../containers/CheckItOut";
import LookingForInspiration from "../../../containers/LookingForInspiration";
import ImageFeatureBlock from "../../../components/ImageFeatureBlock";
import FeatureList from "../../../containers/FeatureList";
import { featureData } from "../../../constants/careers-tomorrow/career-feature-data";
import { careerMotoLists } from "../../../constants/careers-tomorrow/careers-moto-list";
import BreadCrumb from "../../../containers/BreadCrumb";
import Container from "../../../utils/Container";

const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "solutions",
    link: "/solutions",
  },
  {
    text: "careers tomorrow",
    link: "",
  },
];

const CareersTomorrow = ({ data }) => {
  const careerBanner = data.careerBanner.nodes[0];
  const careerInspiration = data.careerInspiration.nodes;
  const careerFooterBanner = data.careerFooterBanner.nodes[0];
  return (
    <Layout>
      <Seo title="Solutions-Careers Tomorrow" />
      <Banner bgImg={careerBanner.desktopImage.gatsbyImageData} mobImg={careerBanner.mobileImage.gatsbyImageData}>
        <div className="absolute top-[24%] lg:left-16 mx-24 sm:mx-0 2xl:left-28 md:top-48 md:left-12 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left w-full sm:w-1/2 -mt-20 md:-mt-40 xl:-mt-16 sm:-mt-6">
          <BannerText
            title={
              <h2 className="text-3xl lg:text-4xl font-bold sm:text-normal xl:text-5xl">
                <strong>build a tomorrow</strong>
                <br />
              </h2>
            }
            subTitle={
              <p className="xl:w-full text-[#000000] font-medium lg:text-3xl xl:text-5xl text-3xl">ready workforce</p>
            }
          />
          <Button
            link="https://entomo.co/request-demo/"
            text="get started today"
            style={{ backgroundColor: "#000", color: "#ffda00" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </Banner>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "2% 1rem" }} />
      </Container>
      <ImageFeatureBlock
        sideImg={CareerFeature}
        sideBottomImage={SideBottomImage}
        subTitle="do you have the resources with the right skills to meet current and future work requirements of your work? Do you know how to enhance your organization and regional skill profile? Is your workforce equipped to acquire new and in-demand skills? 
        the solution lies in finding ways to:"
        featureDataList={[
          "benchmark and identify competencies to be future ready",
          "understand employee skills, performance achievement, potential and aspirations",
          "provide career recommendations for today and tomorrow",
          "contextualize the skill gap analysis for current and aspired roles",
          "personalize learning recommendations with contextual time relevant nudges",
          "always have an enterprise view of Skill Health and talent hotspots",
        ]}
      />
      <div className="bg-black">
        <MotoList featureList={careerMotoLists} />
      </div>
      <Container>
        <FeatureList featureData={featureData} />
      </Container>
      <CheckItOut
        bgImg={TeamBackImg}
        heading="for small teams working
      on game-changing
      innovations"
        description="explore our pre-configured editions that are designed to help you accomplish targeted objectives."
      />
      <LookingForInspiration
        title="looking for inspiration?"
        description="look no further. See how some of our customers are taking performance management to a whole new level."
        storyData={[
          {
            image: careerInspiration[0].storyOne.gatsbyImageData,
            text: "Transforming productivity and performance for over 60,000 employees for a large government agency – MEA",
            fullStoryLink:
              "https://entomo.co/success-stories/democratized-insights-gamification-for-accelerated-productivity-for-24000-plus-employees/",
          },
          {
            image: careerInspiration[0].storyTwo.gatsbyImageData,
            text: "Transforming employee engagement for over 60 business units for this ‘fastest-growing’ conglomerate",
            fullStoryLink:
              "https://entomo.co/success-stories/transforming-employee-engagement-for-over-60-business-units-for-this-fastest-growing-conglomerate/",
          },
        ]}
      />
      <Banner
        height="auto"
        bgImg={careerFooterBanner.desktopImage.gatsbyImageData}
        mobImg={careerFooterBanner.mobileImage.gatsbyImageData}
      >
        <div className="absolute top-[17%] 2xl:left-28 sm:w-1/2 md:left-12 lg:left-20 left-1.5 md:top-48 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 -mt-20 w-fit mx-8 sm:mx-0 md:-mt-40 xl:-mt-16 lg:top-52 font-extrabold sm:left-9">
          <BannerText
            title={
              <h2 className="mt-4 text-white text-3xl 2xl:text-5xl xl:text-5xl">
                <strong>start with the basics</strong>
              </h2>
            }
            subTitle={
              <p className="text-white text-lg sm:w-72 font-normal">
                connect with our team to get an in-depth understanding of all the features.
              </p>
            }
          />
          <Button
            link="https://entomo.co/contact/"
            text="book a demo"
            style={{ backgroundColor: "#FFDA00", color: "#000000" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </Banner>
    </Layout>
  );
};
CareersTomorrow.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default CareersTomorrow;

export const query = graphql`
  query {
    careerBanner: allContentfulBannerContent(filter: { heading: { eq: "Global careers tomorrow page" } }) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    careerInspiration: allContentfulLookingForInspiration(
      filter: { heading: { eq: "Global Solution Careers tomorrow" } }
    ) {
      nodes {
        storyOne {
          gatsbyImageData(formats: WEBP)
        }
        storyTwo {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    careerFooterBanner: allContentfulBannerContent(filter: { heading: { eq: "Global CreersTomorrow Footer Banner" } }) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
  }
`;
