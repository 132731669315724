export const careerMotoLists = [
  {
    title: "future-proof workforce with relevant skills",
    featureList: [
      "always have an enterprise view of Skill Health and talent hotspots.",
      "give your leaders all the important talent Information they need in one place.",
      "identify key areas of talent and skill competencies with skill health indices to power talent decisions.",
    ],
  },
  {
    title: "empower with timely insights",
    featureList: [
      "make your employees CEOs of their careers by providing them the right insights and facilitating meaningful career discussions.",
      "help individual employees understand how their skills fare against their current and aspired roles – and see what they need to learn to get there!",
    ],
  },
  {
    title: "build talent resilience",
    featureList: [
      "seamlessly bring together various employee attributes in one place for easy decisions, actions and career planning.",
      "personalize individual development plans to aspirations and skill gaps of employees.",
    ],
  },
];
