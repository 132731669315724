import {
  CareerFeatureFive,
  CareerFeatureNine,
  CareerFeatureOne,
  CareerFeatureSix,
  CareerFeatureThree,
  CareerFeatureTwo,
  CareerFeatureEight,
  CareerFeatureFour,
  CareerFeatureSeven,
  CareerFeatureTen,
} from "../../images";

export const featureData = [
  {
    title: "competency management",
    featureList: [
      "build or refresh your competency framework with an 80% reduction in cost and effort, seamlessly collaborating with role holders and business leaders.",
      "stay talent competitive with easy-to-use benchmarking against industry competitors.",
    ],
    image: CareerFeatureOne,
  },
  {
    title: "talent mobility",
    featureList: [
      "enable smart recommendations to employees and managers based on skill and aspiration matches on internal jobs, projects and gigs.",
      "transform strategic talent and business decisions with a powerful data-driven talent board.",
    ],
    image: CareerFeatureTwo,
    reversed: "true",
  },
  {
    title: "career pathing",
    featureList: [
      "make your employees the CEOs of their Careers – enable them with AI-powered career recommendations backed by business strategy and individual interests.",
      "shape your organization’s future talent model.",
    ],
    image: CareerFeatureThree,
  },
  {
    title: "talent assessments",
    featureList: [
      "redefine engagement and employee growth with gamified Personality, Attitude and Cognitive Ability and Skill assessments.",
      "give your people an on-demand understanding of where they are at, and where they might want to go.",
    ],
    image: CareerFeatureFour,
    reversed: "true",
  },
  {
    title: "learning plans",
    featureList: [
      "personalize individual development plans to aspirations and skill gaps of employees.",
      "maximize the ROI on learning spend by focusing on what matters most.",
      "enable employees take charge of their development by providing them the tools to do so.",
    ],
    image: CareerFeatureFive,
  },
  {
    title: "reskilling hubs",
    featureList: [
      "take skill development beyond learning programs – drive hands-on project experience and mentoring.",
      "mentor and align cohorts in hubs to share and grow knowledge.",
    ],
    image: CareerFeatureSix,
    reversed: "true",
  },
  {
    title: "succession planning",
    featureList: [
      "enable role and leader criticality capability and risk matrix for hot spot identification.",
      "future-proof your company from potential talent drain and strategic vacancies with increased talent funnel visibility and actionable succession plans.",
    ],
    image: CareerFeatureSeven,
  },
  {
    title: "talent cards",
    featureList: [
      "seamlessly bring together various employee attributes to one place for easy decisions, actions and planning.",
    ],
    image: CareerFeatureEight,
    reversed: "true",
  },
  {
    title: "team development",
    featureList: [
      "push your talent competitiveness discussions with real time health indices, talent hotspots information and drive informed talent decisions.",
    ],
    image: CareerFeatureNine,
  },
  {
    title: "insights and nudges",
    featureList: [
      "democratize information to enable the right decisions at the right time with time-relevant insights.",
      "track key milestones, acknowledge, encourage, reward on-the-go.",
    ],
    image: CareerFeatureTen,
    reversed: "true",
  },
];
